import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';

const EventBanner = ({ text = '', cta1 = {}, cta2 = {} }) => (
  <div className="top-banner top-banner-event">
    <span className="text">{text}</span>

    {cta1.label && (
      <Link {...cta1.nextLink}>
        <Button
          tag="a"
          type="white-green"
          size="small"
          href={cta1.nextLink.url}
          className="top-banner-event-cta top-banner-event-cta1"
        >
          {cta1.label}
        </Button>
      </Link>
    )}

    {cta2.label && (
      <Link {...cta2.nextLink}>
        <Button
          tag="a"
          type="secondary"
          size="small"
          href={cta2.nextLink.url}
          className="top-banner-event-cta top-banner-event-cta2"
        >
          {cta2.label}
        </Button>
      </Link>
    )}
  </div>
);

EventBanner.propTypes = {
  text: PropTypes.string,
  cta1: PropTypes.shape(),
  cta2: PropTypes.shape(),
};

export default EventBanner;
