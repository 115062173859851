import React from 'react';
import PropTypes from 'prop-types';
import EventBanner from './EventBanner';
import AppealBanner from './AppealBanner';
import * as field from '../../../utils/transforms.fields';

const TopBanner = ({ settings, context: { entity } }) => {
  let visibleBanner = null;
  if (
    !settings ||
    (!settings.appealBanner.active && !settings.eventBanner.active) ||
    !entity ||
    !entity.url
  ) {
    return null;
  }

  // Get current entity url.
  const entityUrl = entity.url.url;

  // Check if Appeal banner should be shown.
  const appealBannerVisibility = settings.appealBanner.visibility;
  const appealBannerExcluded = appealBannerVisibility.pathExcludes.includes(entityUrl);
  if (settings.appealBanner.active) {
    // Show on page with configured content types.
    const type = field.getTextValue(entity, 'entity_bundle');
    if (appealBannerVisibility.visibleOnContentType.includes(type) && !appealBannerExcluded) {
      visibleBanner = 'appeal';
    }

    // Path matches.
    if (appealBannerVisibility.pathMatches.includes(entityUrl) && !appealBannerExcluded) {
      visibleBanner = 'appeal';
    }

    // Path starts with.
    appealBannerVisibility.pathStarts.forEach((path) => {
      if (entityUrl.startsWith(path) && !appealBannerVisibility.pathExcludes.includes(entityUrl)) {
        visibleBanner = 'appeal';
      }
    });
  }

  // Check if Event banner should be shown (can override appeal banner).
  const eventBannerVisibility = settings.eventBanner.visibility;
  const eventBannerExcluded = eventBannerVisibility.pathExcludes.includes(entityUrl);
  if (settings.eventBanner.active) {
    // Path matches.
    if (eventBannerVisibility.pathMatches.includes(entityUrl) && !eventBannerExcluded) {
      visibleBanner = 'event';
    }

    // Path starts with.
    eventBannerVisibility.pathStarts.forEach((path) => {
      if (entityUrl.startsWith(path) && !eventBannerExcluded) {
        visibleBanner = 'event';
      }
    });
  }

  // Output banner components.
  if (visibleBanner === 'event') {
    return (
      <EventBanner
        text={settings.eventBanner.text}
        cta1={settings.eventBanner.cta1}
        cta2={settings.eventBanner.cta2}
      />
    );
  }
  if (visibleBanner === 'appeal') {
    if (!settings.appealBanner.url || !settings.appealBanner.url.nextLink) {
      return null;
    }

    return (
      <AppealBanner
        label={settings.appealBanner.label}
        text={settings.appealBanner.text}
        url={settings.appealBanner.url}
        emergencyTag={settings.appealBanner.emergency ? settings.appealBanner.emergencyTag : ''}
      />
    );
  }

  return null;
};

// TODO: The logs shows that 'settings' can be undefined at some point.
//  It needs to find out is it expected or not and
//  either fix providing settings value or remove 'isRequired' property.
TopBanner.propTypes = {
  settings: PropTypes.shape({
    appealBanner: PropTypes.shape().isRequired,
    eventBanner: PropTypes.shape().isRequired,
  }).isRequired,
  context: PropTypes.shape().isRequired,
};

export default TopBanner;
